import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import Service from 'src/Service';
import cloneDeep from 'lodash/cloneDeep';
import { Dialog } from 'primereact/dialog';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from '../../../session/index'
import { BasicLazyParams } from '../../../Utils/constants';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import moment from 'moment';
import { getTimeSlotByKey, warningDailogInit } from '../../../Utils';
import { Specialization } from '../../../Utils/constants';
// import AppointmentInfo from './AppointmentInfo';
import CreateSenderId from './addSenderId';


import ServerlessService from '../../../Service/ServerlessService';
import LoadingComponent from '../../../LoadingComponent';
import Authorize, { PERMISSIONS } from 'src/session/authorize';
import Header from 'src/Header';


class PeIds extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowAddSenderId: false,
            appointments: [],
            lazyParams: { ...BasicLazyParams, sortField: 'createdAt', sortOrder: 1 },
            globalSearch: '',
            isShowSenderId: false,
            appointment: null,
            currentFilter: 1,
            warningDialog: warningDailogInit,
        }
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }


    showBookAnAppointment = () => {
        this.setState({ isShowAddSenderId: true, editSenderId: false });
        // this.props.history.push('/appointment-book')

    }


    onChangeStatusType = (currentFilter) => {
        this.setState((prevState) => {
            return {

                currentFilter: currentFilter,
                lazyParams: BasicLazyParams
            }
        }, this.getAppointments);
    }


    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {

                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, this.getAppointments);
    }

    onPage = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getAppointments);
    }

    onSort = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getAppointments);
    }

    onFilter = (event) => {

        event['first'] = 0;
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters
                }
            };
        }, this.getAppointments);
        //  this.setState({ lazyParams: event }, this.getOrganizations);
    }



    getAppointments = () => {
        let { lazyParams, globalSearch } = this.state;

        this.setState({
            isLoading: true,
            appointments: []
        });
        const url = `/pe-ids/all?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`;
        this.serverlessService.post(url, { status: this.state.currentFilter }, true).then((res) => {


            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                    appointments: res.res.data,
                    totalRecords: res.res.totalRecords
                })

                // this.toast.show({ severity: 'success', summary: 'Success', detail: 'SenderIds fetched successfully.', life: 3000 });
            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })




    }




    editSenderId = (SenderId) => {
        this.setState({
            isShowAddSenderId: true,
            editSenderId: SenderId
        })
    }

    deleteSenderId = (rowData) => {
        let data = { ...rowData }

        this.setState({
            warningDialog: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `You want delete the Principal Entity, it can't be undone once deleted`
            },
            editSenderId: data
        });

    }

    onConfirmChange = () => {
        this.setState({
            warningDialog: warningDailogInit,
            isLoading: true
        });

        const url = `/pe-ids/delete`;
        this.serverlessService.post(url, { _id: this.state.editSenderId._id }, true).then((res) => {


            if (res && res.status && res.res.status) {
                this.setState({
                    editSenderId: null,
                    isLoading: false
                }, () => {
                    this.getAppointments();
                })


                this.toast.show({ severity: 'success', summary: 'Success', detail: 'principal entity deleted successfully.', life: 3000 });
            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })

    }



    actionsTemplate = (rowData) => {
        return (<>
            <div className="p-d-flex sp-mlr10 user-edit-t">

                <Authorize permId={PERMISSIONS.SMS_SENDERID_EDIT} >
                    <span data-pr-tooltip="Edit Principal Entity" data-pr-position="bottom" className={`sp-ml10 edit${rowData._id.substr(2)}`} onClick={() => { this.editSenderId(rowData) }}>
                        <i className='pi pi-pencil' />
                    </span>
                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.edit${rowData._id.substr(2)}`} />
                </Authorize>
                <Authorize permId={PERMISSIONS.SMS_SENDERID_DELETE} >
                    <span data-pr-tooltip="Delete Principal Entity" data-pr-position="bottom" className={`sp-ml10 del${rowData._id.substr(2)}`} onClick={() => { this.deleteSenderId(rowData) }}>
                        <i className='pi pi-trash' />
                    </span>
                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.del${rowData._id.substr(2)}`} />
                </Authorize>
            </div>
        </>
        );
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }


    onSaveSenderId = (isEdit) => {
        this.toast.show({ severity: 'success', summary: `${isEdit ? 'Added' : 'Modified'}`, detail: `Principal Entity ${isEdit ? 'Edited' : 'Created'} Succesfully.`, life: 3000 });


        this.setState({
            isShowAddSenderId: false,
            lazyParams: BasicLazyParams
        }, () => {
            this.getAppointments();
        })

    }


    componentDidMount() {


        this.getAppointments();
    }


    render() {
        const { currentFilter } = this.state;

        console.log(this.state.appointments)
        const header = (
            <div className="table-header sp-tbl-header">
                <div className="sp-tbl-left">
                    <h2 >All Principal Entities</h2>
                    {/* 
                    <Button className={`sp-ml10 cont-btn ${currentFilter != 1 ? 'p-button-outlined' : ''}`} label={'Booked'} onClick={() => this.onChangeStatusType(1)} />
                    <Button className={`sp-ml10 cont-btn ${currentFilter != 2 ? 'p-button-outlined' : ''}`} label={'Visisted'} onClick={() => this.onChangeStatusType(2)} />
                    <Button className={`sp-ml10 cont-btn ${currentFilter != 3 ? 'p-button-outlined' : ''}`} label={'Cancelled'} onClick={() => this.onChangeStatusType(3)} /> */}

                    {this.state.showSearch && <InputText placeholder="Search" className="sp-tbl-search p-inputtext-md"
                        onChange={this.onGlobalSearch}
                        value={this.state.globalSearch}
                    />}
                </div>
                <div className="p-input-icon-left sp-tbl-filter">
                    <ul className="sp-tbl-li">
                        <Authorize permId={PERMISSIONS.SMS_SENDERID_GENERAL_SEARCH} >
                            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search sp-tbl-icon sp-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".sp-tbl-srch" />
                        </Authorize>
                        <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download sp-tbl-icon sp-tbl-dwnld" onClick={this.exportCSV}></i></li>
                        <Tooltip className="table-li-tooltip" target=".sp-tbl-dwnld" />
                    </ul>
                </div>
            </div>
        );


        return (<div>
            <Header>
                <div className='sp-main'>
                    <Authorize permId={PERMISSIONS.SMS_SENDERID_CREATE} >
                        <Button label="+ Add Principal Entity" className='sp-m-lr10'
                            onClick={this.showBookAnAppointment} />
                    </Authorize>
                    {
                        this.state.isShowAddSenderId && <CreateSenderId
                            editSenderId={this.state.editSenderId} onHide={() => {

                                this.setState({
                                    isShowAddSenderId: false
                                })
                            }}

                            onSave={this.onSaveSenderId}
                        />
                    }
                    <Authorize permId={PERMISSIONS.SMS_SENDERID_LIST} >
                        <div className="card datatable-crud-demo sp-m30 fetch-q-tabled">
                            {/* <LazyTable ></LazyTable> */}
                            <DataTable ref={(el) => this.dt = el}
                                //  lazy
                                scrollable
                                responsiveLayout="scroll"
                                //  autoLayout={true}
                                rowHover
                                value={this.state.appointments}
                                // selection={this.state.selectedProducts}
                                // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}
                                dataKey="_id"
                                //</div>={true}
                                lazy
                                onSort={this.onSort}
                                onPage={this.onPage}
                                onFilter={this.onFilter}
                                first={this.state.lazyParams.first}
                                last={this.state.totalRecords}
                                rows={this.state.lazyParams.rows}
                                totalRecords={this.state.totalRecords}
                                paginator
                                rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 300, 500]}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Principal Entities"
                                globalFilter={this.state.globalFilter}
                                header={header}

                            >
                                <Column field="peName" header="Principal Entity Name" />
                                <Column field="peId" header="Principal Entity ID" />
                                {/* <Column field="SenderId" header="SenderId" body={(rowData) => {
                                return <div dangerouslySetInnerHTML={{ __html: rowData.SenderId }}></div>
                            }} /> */}

                                <Column
                                    // headerClassName='option-2'
                                    //headerStyle={{ width: "520px",  }}
                                    //headerStyle={{ display: 'inline-block   ' }}
                                    sortabl body={this.actionsTemplate} header="Actions" ></Column>
                            </DataTable>

                        </div>
                    </Authorize>


                    <Toast ref={(el) => this.toast = el} position="bottom-right" />
                </div>

                <Dialog
                    //header={<></>}
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={true}
                    className='sp-alert-box'
                    dismissableMask={true}
                    closable={false}
                    visible={this.state.warningDialog.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
                    footer={() => {
                        return (<div>
                            <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog: warningDailogInit, editSenderId: null }) }} />
                            <Button label="Yes" className='sp-m-lr10' onClick={this.onConfirmChange} />

                        </div>)
                    }}
                    onHide={() => this.setState({ warningDialog: warningDailogInit, editSenderId: null })}>
                    <div>
                        {/* 
                        <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 382.865 348.356">
                            <g id="warning" transform="translate(0 -20.359)">
                                <path id="Path_3387" data-name="Path 3387" d="M378.275,314.472,222.781,38.439c-13.259-24.107-49.421-24.107-62.68,0L4.606,314.472c-13.259,24.107,3.616,54.242,31.34,54.242H345.73C374.659,367.509,391.534,338.58,378.275,314.472Z" transform="translate(0 0)" fill="#e24c4b" />
                                <path id="Path_3388" data-name="Path 3388" d="M197.907,63.025,353.4,339.058H42.413L197.907,63.025Z" transform="translate(-6.466 -6.505)" fill="#fff" />
                                <g id="Group_1556" data-name="Group 1556" transform="translate(166.037 154.157)">
                                    <path id="Path_3389" data-name="Path 3389" d="M196,207.155l9.643,63.885A15.681,15.681,0,0,0,221.31,284.3h0c7.232,0,14.465-6.027,15.67-13.259l9.643-63.885c2.41-15.67-9.643-28.929-25.313-28.929h0C205.639,178.225,194.79,191.484,196,207.155Z" transform="translate(-195.905 -178.226)" fill="#3f4448" />
                                    <circle id="Ellipse_49" data-name="Ellipse 49" cx="14.465" cy="14.465" r="14.465" transform="translate(10.939 119.332)" fill="#3f4448" />
                                </g>
                            </g>
                        </svg> */}

                    </div>
                    <p className='sp-alert-head'>{this.state.warningDialog.headerMsg}</p>
                    <p className='sp-alert-msg'>{this.state.warningDialog.message}</p>
                </Dialog>

                {
                    this.state.isLoading && <LoadingComponent />
                }
            </Header>
        </div>
        )
    }
}






const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    isSessionChecked: state?.user?.isSessionChecked,
});


export default connect(mapStateToProps, {

})(Authentication(withRouter(PeIds)));
