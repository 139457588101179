import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { Component } from 'react'
export default class PreviewSMS extends Component {





    render() {
        const { users } = this.props;
        console.log(users, 'userssssss')
        return (
            <div>
                {
                    users && users.length ? <>
                        <div className="p-grid">
                            <div className="card datatable-crud-demo sp-mt30">
                                <DataTable ref={(el) => this.dt = el}
                                    value={users}
                                    dataKey="phoneNumber"
                                    paginator
                                    rows={10}
                                    rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 300, 500]}
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                //  globalFilter={this.state.globalFilter}
                                //</div> header={header2}
                                >
                                    <Column field="phoneNumber" header="Phone Number" />
                                    <Column field="message" header="Message" />
                                    <Column field="language" header="Language" />
                                    <Column field="credits" header="Credits" />
                                </DataTable>
                            </div>
                        </div>
                    </> : null}

            </div>
        )
    }
}
