import React, { Component } from 'react'
import { Dialog } from 'primereact/dialog';
import Service from 'src/Service';
import { Toolbar } from 'primereact/toolbar';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import configFields from './config.json';
import LoadingComponent from 'src/LoadingComponent';
import { Specialization } from 'src/Utils/constants';
import { getFormFields, isFormValid, onEmailChange, onNumberChange, onTextChange } from 'src/Utils/formHelper';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from '../session/index'
import Header from 'src/Header';
import './styles.scss'
import { InputTextarea } from 'primereact/inputtextarea';
import ClassicEditor from 'src/ClassicEditor';
import { trimObj } from 'src/Utils';
import ServerlessService from 'src/Service/ServerlessService';

class AddConfig extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(configFields, this.props.config ? this.props.config : {});
        this.state = {
            config: this.formFields.data,
            formValidations: this.formFields.formValidations,
        }
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }



    isAdditionalValidOfStep3 = () => {
        let config = JSON.parse(JSON.stringify(this.state.config));
        let formValidations = this.state.formValidations

        // // password and confirm password
        let isValid = true;
        if (config.password != config.confirmPassword) {
            isValid = false;
            formValidations.fields['confirmPassword'] = { ...formValidations.fields['confirmPassword'], isValid: false, errorMsg: `Password and Confirm password is not matched.` }
        }

        // if (!isValid) {
        this.setState((prevState) => {
            return {
                formValidations
            };
        });
        //}

        return isValid
    }



    saveConfig = () => {

        let isCompleteFormValid = true;
        let config = trimObj(this.state.config)
        const formStatus = isFormValid(configFields, this.formFields.formValidations, config);
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;

        } else if (!this.isAdditionalValidOfStep3()) {
            isCompleteFormValid = false;
        }





        if (isCompleteFormValid) {



            let url = `/config/save`
            if (this.props.config) {
                url = `/config/update`
            }


            this.setState({
                isLoading: true,
            });

            this.serverlessService.post(url, config, true).then((res) => {

                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                    }, () => {


                        this.props.onSave(this.props.config ? true : false);
                    })
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
                }

            }).catch(e => {
                console.log(e);
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            })

        }

    }

    componentDidMount() {
        if (this.props.isSessionChecked && !this.props.authenticated) {
            this.props.history.push('/login')
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSessionChecked != this.props.isSessionChecked && (this.props.isSessionChecked && !this.props.authenticated)) {
            this.props.history.push('/login')
        }

    }

    footer = () => {
        return (<div>
            <Button label={`${this.props.config ? 'Update' : 'Save'} Config`} className='sp-m-lr10'
                onClick={this.saveConfig}
            />
        </div>)
    }


    render() {
        const { config, formValidations } = this.state;
        console.log(config, 'config')
        return (<>
            <Dialog header={`${this.props.config ? 'Edit' : "Add"} Config`}
                blockScroll={true}
                draggable={false}
                closeOnEscape={true}
                dismissableMask={true}
                visible={true}
                footer={this.footer}
                closable={true}
                style={{ width: '600px', }}
                onHide={this.props.onHide}>
                <div>
                    <div className="p-grid sp-mt20">
                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label'>Host Name<span className='sp-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                            <InputText value={config.host}
                                autoComplete="new-password"
                                className='sp-w100p p-inputtext-style1'
                                onChange={(e) => { onTextChange(e.target.value, 'host', this, configFields, config, formValidations, 'config', 'formValidations') }} />
                            {formValidations && !formValidations.fields['host'].isValid && <p className="p-error">{formValidations.fields['host'].errorMsg}</p>}
                        </div>
                    </div>
                    <div className="p-grid ">
                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label' >Email ? Username<span className='sp-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                            <InputText value={config.email}
                                autoComplete="new-password"
                                className='sp-w100p p-inputtext-style1'
                                onChange={(e) => { onEmailChange(e.target.value, 'email', this, configFields, config, formValidations, 'config', 'formValidations') }} />
                            {formValidations && !formValidations.fields['email'].isValid && <p className="p-error">{formValidations.fields['email'].errorMsg}</p>}
                        </div>
                    </div>
                    <div className="p-grid ">
                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label' >Password<span className='sp-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                            <InputText value={config.password}
                                autoComplete="new-password"
                                className='sp-w100p p-inputtext-style1'
                                onChange={(e) => { onTextChange(e.target.value, 'password', this, configFields, config, formValidations, 'config', 'formValidations') }} />
                            {formValidations && !formValidations.fields['password'].isValid && <p className="p-error">{formValidations.fields['password'].errorMsg}</p>}
                        </div>
                    </div>
                    <div className="p-grid ">
                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label' >Confirm Password<span className='sp-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                            <InputText value={config.confirmPassword}
                                autoComplete="new-password"
                                className='sp-w100p p-inputtext-style1'
                                onChange={(e) => { onTextChange(e.target.value, 'confirmPassword', this, configFields, config, formValidations, 'config', 'formValidations') }} />
                            {formValidations && !formValidations.fields['confirmPassword'].isValid && <p className="p-error">{formValidations.fields['confirmPassword'].errorMsg}</p>}
                        </div>
                    </div>
                    <div className="p-grid ">
                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label'>Sender<span className='sp-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">

                            <InputText value={config.sender}
                                autoComplete="new-password"
                                placeholder={`example@email.com`}
                                className='sp-w100p p-inputtext-style1'
                                onChange={(e) => { onEmailChange(e.target.value, 'sender', this, configFields, config, formValidations, 'config', 'formValidations') }} />
                            {formValidations && !formValidations.fields['sender'].isValid && <p className="p-error">{formValidations.fields['sender'].errorMsg}</p>}

                            {/* <p><b>Ex:</b> {`example@email.com`}</p> */}
                        </div>
                    </div>
                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}
            </Dialog>
        </>
        )
    }
}






const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    isSessionChecked: state?.user?.isSessionChecked,
});


export default connect(mapStateToProps, {

})(Authentication(withRouter(AddConfig)));
