import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import Service from 'src/Service';
import cloneDeep from 'lodash/cloneDeep';
import { Dialog } from 'primereact/dialog';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from '../session/index'
import { BasicLazyParams } from 'src/Utils/constants';
import { DataTable } from 'primereact/datatable';
import { MultiSelect } from 'primereact/multiselect';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import moment from 'moment';
import { getTimeSlotByKey, warningDailogInit } from 'src/Utils';
import { Specialization } from 'src/Utils/constants';
// import AppointmentInfo from './AppointmentInfo';
import CreateUser from './createUser';
import Header from './../Header';
import UserView from './userView';
import Authorize, { PERMISSIONS } from 'src/session/authorize';
import ServerlessService from 'src/Service/ServerlessService';
import LoadingComponent from 'src/LoadingComponent';

class EmailUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowAddUser: false,
            emailUsers: [],
            lazyParams: { ...BasicLazyParams, sortField: 'createdAt', sortOrder: 1 },
            globalSearch: '',
            isShowUser: false,
            emailUser: null,
            currentFilter: 1,
            warningDialog: warningDailogInit,
            tags: [],
            selectedTags: []
        }

        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }


    showBookAnAppointment = () => {
        this.setState({ isShowAddUser: true, editUser: false });
        // this.props.history.push('/emailUser-book')

    }


    onChangeStatusType = (currentFilter) => {
        this.setState((prevState) => {
            return {

                currentFilter: currentFilter,
                lazyParams: BasicLazyParams
            }
        }, this.getEmailUsers);
    }


    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {

                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, this.getEmailUsers);
    }

    onPage = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getEmailUsers);
    }

    onSort = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getEmailUsers);
    }

    onFilter = (event) => {

        event['first'] = 0;
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters
                }
            };
        }, this.getEmailUsers);
        //  this.setState({ lazyParams: event }, this.getOrganizations);
    }



    getEmailUsers = () => {
        let { lazyParams, globalSearch } = this.state;

        this.setState({
            isLoading: true,
            emailUsers: []
        });
        const url = `/emailusers/all?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`;
        this.serverlessService.post(url, { tags: this.state.selectedTags }, true).then((res) => {


            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                    emailUsers: res.res.data,
                    totalRecords: res.res.totalRecords
                })

                // this.toast.show({ severity: 'success', summary: 'Success', detail: 'Users fetched successfully.', life: 3000 });
            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })




    }

    getTags = () => {

        this.setState({
            isLoading: true,
            tags: []
        });
        const url = `/emailusers-tags/all`;
        this.serverlessService.post(url, { status: this.state.selectedTags }, true).then((res) => {

            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                    tags: res.res.data,
                })
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }
        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })




    }



    showUser = (user) => {

        this.setState({
            isShowUser: true,
            user: user
        })
    }


    editUser = (user) => {
        this.setState({
            isShowAddUser: true,
            editUser: user
        })
    }

    deleteUser = (rowData) => {
        let data = { ...rowData }

        this.setState({
            warningDialog: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `You want delete the user, it can't be undone once deleted`
            },
            editUser: data
        });

    }

    onConfirmChange = () => {
        this.setState({
            warningDialog: warningDailogInit,
            isLoading: true
        });

        const url = `/emailusers/delete`;
        this.serverlessService.post(url, { _id: this.state.editUser._id }, true).then((res) => {


            if (res && res.status && res.res.status) {
                this.setState({
                    editUser: null,
                    isLoading: false
                }, () => {
                    this.getEmailUsers();
                })


                this.toast.show({ severity: 'success', summary: 'Success', detail: 'User deleted successfully.', life: 3000 });
            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })

    }



    actionsUser = (rowData) => {
        return (<>
            <div className="p-d-flex sp-mlr10 user-edit-t">
                {/* <span data-pr-tooltip="View User" data-pr-position="bottom" className={`sp-ml10 anal${rowData._id.substr(2)}`} onClick={() => this.showUser(rowData)} >
                    <i className='pi pi-eye' />
                </span>
                <Tooltip className="table-li-tooltip2" autoHide={false} target={`.anal${rowData._id.substr(2)}`} /> */}
                <Authorize permId={PERMISSIONS.EMAIL_USERS_EDIT} >
                    <span data-pr-tooltip="Edit User" data-pr-position="bottom" className={`sp-ml10 edit${rowData._id.substr(2)}`} onClick={() => { this.editUser(rowData) }}>
                        <i className='pi pi-pencil' />
                    </span>
                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.edit${rowData._id.substr(2)}`} />
                </Authorize>
                <Authorize permId={PERMISSIONS.EMAIL_USERS_DELETE} >
                    <span data-pr-tooltip="Delete User" data-pr-position="bottom" className={`sp-ml10 del${rowData._id.substr(2)}`} onClick={() => { this.deleteUser(rowData) }}>
                        <i className='pi pi-trash' />
                    </span>
                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.del${rowData._id.substr(2)}`} />
                </Authorize>
            </div>
        </>
        );
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }


    onSaveUser = (isEdit) => {
        this.toast.show({ severity: 'success', summary: `${isEdit ? 'Added' : 'Modified'}`, detail: `User ${isEdit ? 'Edited' : 'Created'} Succesfully.`, life: 3000 });


        this.setState({
            isShowAddUser: false,
            lazyParams: BasicLazyParams
        }, () => {
            this.getEmailUsers();
            this.getTags();
        })

    }


    componentDidMount() {


        this.getEmailUsers();
        this.getTags();
    }


    render() {
        const { currentFilter } = this.state;

        console.log(this.state.emailUsers)
        const header = (
            <div className="table-header sp-tbl-header">
                <div className="sp-tbl-left">
                    <h2 >All Users</h2>
                    {/* 
                    <Button className={`sp-ml10 cont-btn ${currentFilter != 1 ? 'p-button-outlined' : ''}`} label={'Booked'} onClick={() => this.onChangeStatusType(1)} />
                    <Button className={`sp-ml10 cont-btn ${currentFilter != 2 ? 'p-button-outlined' : ''}`} label={'Visisted'} onClick={() => this.onChangeStatusType(2)} />
                    <Button className={`sp-ml10 cont-btn ${currentFilter != 3 ? 'p-button-outlined' : ''}`} label={'Cancelled'} onClick={() => this.onChangeStatusType(3)} /> */}

                    {this.state.showSearch && <InputText placeholder="Search" className="sp-tbl-search p-inputtext-md"
                        onChange={this.onGlobalSearch}
                        value={this.state.globalSearch}
                    />}
                </div>
                <div className="p-input-icon-left sp-tbl-filter">
                    <ul className="sp-tbl-li">
                        <Authorize permId={PERMISSIONS.EMAIL_USERS_GENERAL_SEARCH} >
                            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search sp-tbl-icon sp-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".sp-tbl-srch" />
                        </Authorize>
                        <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download sp-tbl-icon sp-tbl-dwnld" onClick={this.exportCSV}></i></li>
                        <Tooltip className="table-li-tooltip" target=".sp-tbl-dwnld" />
                    </ul>
                </div>
            </div>
        );


        return (<div>
            <Header>
                <div className='sp-main'>


                    {
                        this.state.isShowAddUser && <CreateUser
                            editUser={this.state.editUser} onHide={() => {

                                this.setState({
                                    isShowAddUser: false
                                })
                            }}

                            onSave={this.onSaveUser}
                        />
                    }
                    <Toolbar className="sp-toolbar"
                        right={() => {
                            return (
                                <Authorize permId={PERMISSIONS.EMAIL_USERS_ADD} >
                                    <Button label="+ Add User" className='sp-m-lr10'
                                        onClick={this.showBookAnAppointment} />
                                </Authorize>
                            )
                        }}
                        left={() => {
                            return (<>
                                <Authorize permId={PERMISSIONS.EMAIL_USERS_GENERAL_SEARCH} >
                                    <p>Search Users By tags</p>
                                    <MultiSelect value={this.state.selectedTags} options={this.state.tags} onChange={(e) => {
                                        this.setState({
                                            selectedTags: e.value
                                        })


                                    }}
                                    // filter={true}
                                    // filterPlaceholder='Search here..'
                                    // filterBy='label'
                                    />
                                    <Button label="Search" className='sp-m-lr10'
                                        onClick={this.getEmailUsers} />
                                </Authorize>    </>)
                        }}


                    ></Toolbar>



                    <Authorize permId={PERMISSIONS.EMAIL_USERS_LIST} >
                        <div className="card datatable-crud-demo sp-m30 fetch-q-tabled">
                            {/* <LazyTable ></LazyTable> */}
                            <DataTable ref={(el) => this.dt = el}
                                //  lazy
                                scrollable
                                responsiveLayout="scroll"
                                //  autoLayout={true}
                                rowHover
                                value={this.state.emailUsers}
                                // selection={this.state.selectedProducts}
                                // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}
                                dataKey="_id"
                                //</div>={true}
                                lazy
                                onSort={this.onSort}
                                onPage={this.onPage}
                                onFilter={this.onFilter}
                                first={this.state.lazyParams.first}
                                last={this.state.totalRecords}
                                rows={this.state.lazyParams.rows}
                                totalRecords={this.state.totalRecords}
                                paginator
                                rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 300, 500]}
                                paginatorUser="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportUser="Showing {first} to {last} of {totalRecords} products"
                                globalFilter={this.state.globalFilter}
                                header={header}

                            >
                                <Column field="name" header="Name" />
                                <Column field="email" header="Email" />
                                <Column sortable field="address" header="Address" />
                                <Column sortable field="phoneNumber" header="Phone Number" />
                                <Column sortable field="tags" header="Tags" body={(rowData) => {
                                    return <>
                                        {
                                            rowData.tags.join(',')

                                        }
                                    </>
                                }} />
                                <Column sortable field="createdBy" header="Added By" body={(rowData) => {
                                    return <>
                                        {
                                            rowData.createdBy?.name

                                        }
                                    </>
                                }} />
                                <Column
                                    // headerClassName='option-2'
                                    //headerStyle={{ width: "520px",  }}
                                    //headerStyle={{ display: 'inline-block   ' }}
                                    sortabl body={this.actionsUser} header="Actions" ></Column>
                            </DataTable>

                        </div>
                    </Authorize>

                    {
                        this.state.isShowUser && <>
                            <UserView
                                onHide={() => {
                                    this.setState({
                                        isShowUser: false
                                    })
                                }}
                                user={this.state.user}
                            />
                        </>
                    }
                    <Toast ref={(el) => this.toast = el} position="bottom-right" />
                </div>

                <Dialog
                    //header={<></>}
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={true}
                    className='sp-alert-box'
                    dismissableMask={true}
                    closable={false}
                    visible={this.state.warningDialog.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
                    footer={() => {
                        return (<div>
                            <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog: warningDailogInit, editUser: null }) }} />
                            <Button label="Yes" className='sp-m-lr10' onClick={this.onConfirmChange} />

                        </div>)
                    }}
                    onHide={() => this.setState({ warningDialog: warningDailogInit, editUser: null })}>
                    <div>
                        {/* 
                        <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 382.865 348.356">
                            <g id="warning" transform="translate(0 -20.359)">
                                <path id="Path_3387" data-name="Path 3387" d="M378.275,314.472,222.781,38.439c-13.259-24.107-49.421-24.107-62.68,0L4.606,314.472c-13.259,24.107,3.616,54.242,31.34,54.242H345.73C374.659,367.509,391.534,338.58,378.275,314.472Z" transform="translate(0 0)" fill="#e24c4b" />
                                <path id="Path_3388" data-name="Path 3388" d="M197.907,63.025,353.4,339.058H42.413L197.907,63.025Z" transform="translate(-6.466 -6.505)" fill="#fff" />
                                <g id="Group_1556" data-name="Group 1556" transform="translate(166.037 154.157)">
                                    <path id="Path_3389" data-name="Path 3389" d="M196,207.155l9.643,63.885A15.681,15.681,0,0,0,221.31,284.3h0c7.232,0,14.465-6.027,15.67-13.259l9.643-63.885c2.41-15.67-9.643-28.929-25.313-28.929h0C205.639,178.225,194.79,191.484,196,207.155Z" transform="translate(-195.905 -178.226)" fill="#3f4448" />
                                    <circle id="Ellipse_49" data-name="Ellipse 49" cx="14.465" cy="14.465" r="14.465" transform="translate(10.939 119.332)" fill="#3f4448" />
                                </g>
                            </g>
                        </svg> */}

                    </div>
                    <p className='sp-alert-head'>{this.state.warningDialog.headerMsg}</p>
                    <p className='sp-alert-msg'>{this.state.warningDialog.message}</p>
                </Dialog>

                {
                    this.state.isLoading && <LoadingComponent />
                }
            </Header>
        </div>
        )
    }
}






const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    isSessionChecked: state?.user?.isSessionChecked,
});


export default connect(mapStateToProps, {

})(Authentication(withRouter(EmailUsers)));
