
// // dev
// const EMAIL_SERVER_URL = 'http://localhost:7878/api';
// const SERVERLESS_URL = 'http://localhost:7878/api';
// const SMS_SERVER_URL = 'http://localhost:7878/api';
// const OPEN_API_ENDPOINT = 'http://localhost:7878/api';
//const TINY_BASE_URL = 'http://localhost:7878'

// // prod

// sas-prod
const EMAIL_SERVER_URL = 'https://secureapi.sendshortly.com/api';
const SMS_SERVER_URL = 'https://secureapi.sendshortly.com/api';
const SERVERLESS_URL = 'https://secureapi.sendshortly.com/api'
const OPEN_API_ENDPOINT = '-';





const TINY_BASE_URL = 'sstiny.in'
const ORG_CODE = 'aau'

//const TINY_BASE_URL = 'sms.sstiny.com'

module.exports = {
    EMAIL_SERVER_URL,
    SERVERLESS_URL,
    OPEN_API_ENDPOINT,
    SMS_SERVER_URL,
    TINY_BASE_URL,
    ORG_CODE
}