import React, { Component } from 'react'
import ServerlessService from 'src/Service/ServerlessService';
import Service from 'src/Service';
import { Button } from 'primereact/button';
import { warningDailogInit } from 'src/Utils';
import { Dialog } from 'primereact/dialog';
import LoadingComponent from 'src/LoadingComponent';
import './style.scss'
import Authorize, { PERMISSIONS } from 'src/session/authorize';

export default class APIKey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            apiKey: null,
            warningDialog2: warningDailogInit,
        }
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }


    componentDidMount() {
        this.getApiKey();
    }


    getApiKey = (orgId) => {
        this.setState({
            isLoading: true,

        })
        const url = `/admin/get-api-details`;
        this.service.get(url, true).then((res) => {

            debugger
            if (res && res.res && res.res.status) {

                if (res.res.data.apiKey) {
                    this.setState({
                        isLoading: false,
                        apiKey: res.res.data.apiKey
                    })
                } else {
                    this.setState({
                        isLoading: false,
                        isNotAdded: true
                    })
                }



            } else {


                //  this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });


            }


        }).catch(e => {
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            this.setState({
                isLoading: false
            });
        });


    }



    addOrUpdateAPIKey = () => {
        // let msg = '';
        if (this.state.apiKey) {
            let msg = 'Old API Key will not work. You have to use new API key to work properly. Shall I Generate new API Key.'
            this.setState({
                warningDialog2: {
                    visible: true,
                    headerMsg: 'Are you Sure?',
                    message: msg
                },
            });
        } else {
            this.onSubmitClick();

        }


    }



    onSubmitClick = () => {
        this.setState({
            isLoading: true,
            warningDialog2: warningDailogInit
        });

        const url = `/admin/update-api-details`;
        this.service.get(url, true).then((res) => {
            debugger
            if (res && res.res && res.res.status) {
                this.setState({
                    isLoading: false,
                    apiKey: res.res.data.apiKey,
                });

            } else {

                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }


        }).catch(e => {
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            this.setState({
                isLoading: false
            });
        });

    }


    onCopyClick = (text) => {

        debugger
        navigator.clipboard.writeText(text);
    }


    render() {

        const { apiKey } = this.state;
        return (
            <div className='sp-card api-config '>
                <div>
                    <p className='config-txt'><b> API Key:</b>{apiKey ? <> <span className='api-key ' >{apiKey} </span> <span className='copy-txt' onClick={() => { this.onCopyClick(apiKey) }}>Copy</span> </> : 'No API key generated'}</p>
                    <Authorize permId={PERMISSIONS.SMS_API_DOC_GEN_API_KEY} >
                        <Button label={'New API Key'} className='sp-mt10 sp-mlr10' onClick={this.addOrUpdateAPIKey} />
                    </Authorize>
                </div>

                <Dialog
                    //header={<></>}
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={true}
                    className='sp-alert-box'
                    dismissableMask={true}
                    closable={false}
                    visible={this.state.warningDialog2.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
                    footer={() => {
                        return (<div>
                            <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog2: warningDailogInit }) }} />
                            <Button label="Yes" className='sp-m-lr10' onClick={this.onSubmitClick} />

                        </div>)
                    }}
                    onHide={() => this.setState({ warningDialog2: warningDailogInit })}>
                    <div>
                    </div>
                    <p className='sp-alert-head'>{this.state.warningDialog2.headerMsg}</p>
                    <p className='sp-alert-msg'>{this.state.warningDialog2.message}</p>
                </Dialog>

                {this.state.isLoading && <LoadingComponent />}
            </div>
        )
    }
}
