import { Button } from 'primereact/button';
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import Authentication from './../session/index';

import Header from './../Header';
import './styles.scss'
import { Card } from 'primereact/card';
import CreditPanel from './creditPanel';
class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        console.log(this.props)
        return (<>
            <Header>
                <div className='home-section1'>
                    <div className='sp-main home-section1-w'>
                        <h1>Welcome</h1>
                        <h1>To Email /SMS Compaign</h1>
                        <p>The world of Digital Compaign platform</p>
                        {/* <CreditPanel /> */}
                    </div>
                </div>
            </Header>
        </>
        )
    }
}







const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
});


export default connect(mapStateToProps, {
})(Authentication(withRouter(HomePage)));
