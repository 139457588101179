import React, { Component } from 'react'
import { Dialog } from 'primereact/dialog';
import Service from '../../../Service';
import { Toolbar } from 'primereact/toolbar';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import urlFields from './url.json';
import LoadingComponent from '../../../LoadingComponent';
import { Specialization } from '../../../Utils/constants';
import { getFormFields, isFormValid, onEmailChange, onNumberChange, onTextChange } from '../../../Utils/formHelper';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from '../../../session/index'
import './styles.scss'
import { InputTextarea } from 'primereact/inputtextarea';
import ClassicEditor from '../../../ClassicEditor';
import { trimObj } from '../../../Utils';
import ServerlessService from '../../../Service/ServerlessService';
import { TINY_BASE_URL } from 'src/Service/config';

class CreateUrl extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(urlFields, this.props.editUrl ? this.props.editUrl : {});
        this.state = {
            url: this.formFields.data,
            formValidations: this.formFields.formValidations,
        }
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }


    onCopyClick = (text) => {

        debugger
        navigator.clipboard.writeText(text);
    }


    saveUrl = () => {
        let urlData = trimObj(this.state.url)
        const formStatus = isFormValid(urlFields, this.formFields.formValidations, urlData);
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
        } else {
            let url = `/url-shortener/save`
            if (this.props.editUrl) {
                url = `/url-shortener/update`
            }


            this.setState({
                isLoading: true,
            });

            this.serverlessService.post(url, urlData, true).then((res) => {
                debugger
                if (res && res.status && res.res.status) {
                    this.setState((prevState) => {
                        return {
                            isLoading: false,
                            url: res.res.data
                        };
                    }, () => {
                        this.props.onSave(this.props.editUrl ? true : false);
                    });


                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
                }

            }).catch(e => {
                console.log(e);
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            })

        }

    }

    componentDidMount() {
        if (this.props.isSessionChecked && !this.props.authenticated) {
            this.props.history.push('/login')
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSessionChecked != this.props.isSessionChecked && (this.props.isSessionChecked && !this.props.authenticated)) {
            this.props.history.push('/login')
        }

    }

    footer = () => {
        return (<div>

        </div>)
    }


    render() {
        const { url, formValidations } = this.state;

        console.log(this.state, 'state-appointment')
        return (<>
            <Dialog header={`${this.props.editUrl ? 'Edit' : 'Create'} Short URL`}
                blockScroll={true}
                draggable={false}
                closeOnEscape={true}
                dismissableMask={true}
                visible={true}
                footer={this.footer}
                closable={true}
                style={{ minWidth: '90%', maxWidth: '1000px' }}
                onHide={this.props.onHide}>

                <div>
                    <div className="p-grid sp-mt20">


                        <div className="p-col-12">
                            <p className='text-label'>URL to shorten<span className='sp-required'>*</span></p>
                            <InputText value={url.urlToShorten}
                                autoComplete="new-password"
                                className='sp-w100p p-inputtext-style1'
                                onChange={(e) => { onTextChange(e.target.value, 'urlToShorten', this, urlFields, url, formValidations, 'url', 'formValidations') }} />
                            {formValidations && !formValidations.fields['urlToShorten'].isValid && <p className="p-error">{formValidations.fields['urlToShorten'].errorMsg}</p>}
                        </div>
                    </div>
                    <div>
                        <Button label={`${url && url.shortUrlKey ? 'Update' : 'Get'} Url`}
                            // disabled={url && url.shortUrlKey}
                            className=''
                            onClick={this.saveUrl}
                        />
                    </div>
                    <div className="p-grid sp-mt20">
                        <div className="p-col-12">

                            {
                                url && url.shortUrlKey && <>
                                    <p className='text-label'>Short URL</p>
                                    <p>
                                        <span className='api-key ' >{TINY_BASE_URL}/{url.shortUrlKey} </span> <span className='copy-txt' onClick={() => { this.onCopyClick(TINY_BASE_URL + '/' + url.shortUrlKey) }}>Copy</span>
                                    </p>
                                </>
                            }
                        </div>
                    </div>
                </div>

                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}

            </Dialog>
        </>
        )
    }
}






const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    isSessionChecked: state?.user?.isSessionChecked,
});


export default connect(mapStateToProps, {

})(Authentication(withRouter(CreateUrl)));
