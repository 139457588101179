import React, { Component } from 'react'
import { Dialog } from 'primereact/dialog';
import Service from '../../../Service';
import { RadioButton } from 'primereact/radiobutton';
import { Toolbar } from 'primereact/toolbar';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import templateFields from './template.json';
import LoadingComponent from '../../../LoadingComponent';
import { Specialization } from '../../../Utils/constants';
import { getFormFields, isFormValid, onEmailChange, onNumberChange, onTextChange, onDropDownChange, onChipsChange } from '../../../Utils/formHelper';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from '../../../session/index'
import Header from '../../../Header';
import './styles.scss'
import { InputTextarea } from 'primereact/inputtextarea';
import ClassicEditor from '../../../ClassicEditor';
import { trimObj } from '../../../Utils';
import ServerlessService from '../../../Service/ServerlessService';
import { MultiSelect } from 'primereact/multiselect';



class CreateTemplate extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(templateFields, this.props.template ? this.props.template : {});
        this.state = {
            template: this.formFields.data,
            formValidations: this.formFields.formValidations,
            senderIds: []
        }
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }



    saveTemplate = () => {







        let templateData = trimObj(this.state.template)
        const formStatus = isFormValid(templateFields, this.formFields.formValidations, templateData);
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
        } else {
            let url = `/sms-templates/save`
            if (this.props.template) {
                url = `/sms-templates/update`
            }


            this.setState({
                isLoading: true,
            });

            this.serverlessService.post(url, templateData, true).then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                    }, () => {
                        this.props.onSave(this.props.template ? true : false);
                    })
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
                }
            }).catch(e => {
                console.log(e);
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            })

        }

    }


    getFormData = () => {
        this.setState({
            isLoading: true,
            appointments: []
        });
        const url = `/sms-templates/form-data`;
        this.serverlessService.get(url, {}, true).then((res) => {

            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                    senderIds: res.res.data?.senderIds,
                    peIds: res.res.data?.peIds,
                    //  peId: res.res.data.peId,
                    template: {
                        ...this.state.template,
                        //  peId: res.res.data.peId

                    }
                })

                // this.toast.show({ severity: 'success', summary: 'Success', detail: 'Headers fetched successfully.', life: 3000 });
            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })


    }



    componentDidMount() {
        if (this.props.isSessionChecked && !this.props.authenticated) {
            this.props.history.push('/login')
        }


        this.getFormData();


    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSessionChecked != this.props.isSessionChecked && (this.props.isSessionChecked && !this.props.authenticated)) {
            this.props.history.push('/login')
        }

    }

    footer = () => {
        return (<div>
            <Button label={`${this.props.template ? 'Update' : 'Save'} Template`} className='sp-m-lr10'
                onClick={this.saveTemplate}
            />
        </div>)
    }


    render() {
        const { template, formValidations, senderIds } = this.state;

        //  console.log(this.state, 'state-appointment')
        return (<>
            <Dialog header={`${this.props.template ? 'Edit' : 'Add'} Template`}
                blockScroll={true}
                draggable={false}
                closeOnEscape={true}
                dismissableMask={true}
                visible={true}
                footer={this.footer}
                closable={true}
                style={{ width: '90%', height: '90vh' }}
                onHide={this.props.onHide}>

                <div>
                    <div className="p-grid ">

                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label'>Template Name<span className='sp-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                            <InputText value={template.templateName}
                                autoComplete="new-password"
                                className='sp-w200 p-inputtext-style1'
                                onChange={(e) => { onTextChange(e.target.value, 'templateName', this, templateFields, template, formValidations, 'template', 'formValidations') }} />
                            {formValidations && !formValidations.fields['templateName'].isValid && <p className="p-error">{formValidations.fields['templateName'].errorMsg}</p>}
                        </div>
                    </div>
                    <div className="p-grid ">

                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label'>Template ID<span className='sp-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                            <InputText value={template.templateId}
                                autoComplete="new-password"
                                className='sp-w200 p-inputtext-style1'
                                onChange={(e) => { onTextChange(e.target.value, 'templateId', this, templateFields, template, formValidations, 'template', 'formValidations') }} />
                            {formValidations && !formValidations.fields['templateId'].isValid && <p className="p-error">{formValidations.fields['templateId'].errorMsg}</p>}
                        </div>
                    </div>
                    <div className="p-grid ">
                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label'>Template Message<span className='sp-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                            <InputTextarea value={template.templateMessage}
                                autoComplete="new-password"
                                className='sp-w100p p-inputtext-style1'
                                rows={4}
                                onChange={(e) => { onTextChange(e.target.value, 'templateMessage', this, templateFields, template, formValidations, 'template', 'formValidations') }} />
                            {formValidations && !formValidations.fields['templateMessage'].isValid && <p className="p-error">{formValidations.fields['templateMessage'].errorMsg}</p>}
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label'>Content Type</p>
                        </div>
                        <div className="p-col-8 p-md-10 p-lg-10 sp-mt2">
                            <RadioButton className="radio-inline" value='serviceimplicit' inputId="si"
                                name="templateType"
                                onChange={(e) => onTextChange(e.value, 'templateType', this, templateFields, template, formValidations, 'template', 'formValidations')}
                                checked={template.templateType === 'serviceimplicit'} />
                            <label className='sp-label-s1 ' htmlFor="si">Service Implicit</label>
                            <RadioButton className="radio-inline  sp-ml10" value='serviceexplicit' inputId="se"
                                name="templateType"
                                onChange={(e) => onTextChange(e.value, 'templateType', this, templateFields, template, formValidations, 'template', 'formValidations')}
                                checked={template.templateType === 'serviceexplicit'} />
                            <label className='sp-label-s1' htmlFor="se">Service Explicit</label>
                            <RadioButton className="radio-inline  sp-ml10" value='promotional' inputId="se"
                                name="templateType"
                                onChange={(e) => onTextChange(e.value, 'templateType', this, templateFields, template, formValidations, 'template', 'formValidations')}
                                checked={template.templateType === 'promotional'} />
                            <label className='sp-label-s1' htmlFor="se">Promotional</label>
                            {formValidations && !formValidations.fields['templateType'].isValid && <p className="p-error">{formValidations.fields['templateType'].errorMsg}</p>}
                        </div>
                    </div>
                    <div className="p-grid ">
                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label'>SenderIds<span className='sp-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-10 p-lg-10 sp-mt2">
                            <MultiSelect value={template.senderIds}
                                options={senderIds}
                                optionLabel='senderId'
                                optionValue='senderId'
                                className='sp-w200 '
                                onChange={(e) => onChipsChange(e.value, 'senderIds', this, templateFields, template, formValidations, 'template', 'formValidations')}
                                placeholder="Select Sender Id" />
                            {formValidations && !formValidations.fields['senderIds'].isValid && <p className="p-error">{formValidations.fields['senderIds'].errorMsg}</p>}
                        </div>
                    </div>
                    <div className="p-grid ">
                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label'>Principal Entity <span className='sp-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-10 p-lg-10 sp-mt2">
                            <Dropdown value={template.peId}
                                options={this.state.peIds}
                                optionLabel='peName'
                                optionValue='peId'
                                onChange={(e) => onTextChange(e.value, 'peId', this, templateFields, template, formValidations, 'template', 'formValidations')}
                                placeholder="Select Principal Entity" />
                            {formValidations && !formValidations.fields['peId'].isValid && <p className="p-error">{formValidations.fields['peId'].errorMsg}</p>}
                        </div>
                    </div>
                    <div className="p-grid ">
                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label'>Principal Entity Name<span className='sp-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                            <InputText value={template.peId}
                                disabled
                                className='sp-w200 p-inputtext-style1'
                            />
                            {formValidations && !formValidations.fields['peId'].isValid && <p className="p-error">{formValidations.fields['peId'].errorMsg}</p>}
                        </div>
                    </div>
                </div>
                <p className='upload-note sp-mt20'><span>Note:</span> Please enter all the information as registered in DLT. Otherwise messages will not be delivered</p>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}

            </Dialog>
        </>
        )
    }
}






const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    isSessionChecked: state?.user?.isSessionChecked,
});


export default connect(mapStateToProps, {

})(Authentication(withRouter(CreateTemplate)));
