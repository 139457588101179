import React, { Component } from 'react'
import { Dialog } from 'primereact/dialog';
import Service from 'src/Service';
import { v4 as uuidv4 } from 'uuid';
import { Toolbar } from 'primereact/toolbar';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import templateFields from './template.json';
import LoadingComponent from 'src/LoadingComponent';
import { Specialization } from 'src/Utils/constants';
import { getFormFields, isFormValid, onEmailChange, onNumberChange, onTextChange } from 'src/Utils/formHelper';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from '../session/index'
import Header from 'src/Header';
import './styles.scss'
import { InputTextarea } from 'primereact/inputtextarea';
import ClassicEditor from 'src/ClassicEditor';
import { bytesToSize, isValidFileSize, trimObj } from 'src/Utils';
import FileUpload from 'src/components/common/fileUpload';
import axios from 'axios';
import ServerlessService from 'src/Service/ServerlessService';

class CreateTemplate extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(templateFields, this.props.template ? this.props.template : {});
        this.state = {
            template: this.formFields.data,
            formValidations: this.formFields.formValidations,
            uploadedFilesInfo: this.props.template ? this.props.template.attachments || [] : []
        }
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }



    // uploadFilesToS3 = async (files) => {
    //     
    //     for (let i = 0; i < files.length; i++) {
    //         files[i].key = uuidv4() + files[i].name;

    //         let keyToUpload = await fetchURL2(files[i].key);
    //         let abc = await uploadContent2(keyToUpload, files[i]);
    //         
    //     }
    // }

    uploadFilesToS3 = async (files) => {
        let url = 'https://v2ao5dt0v3.execute-api.ap-south-1.amazonaws.com/prod/v1/data/presign/url';
        let cloudFrontEndpoint = 'https://d1wqfechwjsewm.cloudfront.net/';
        let uploadedFiles = [];
        let thisObj = this;
        //  let files = e.target.files;
        for (let i = 0; i < files.length; i++) {



            if (!files[i].status) {


                let { data } = await axios.post(url, {
                    "type": "insert",
                    "key": thisObj.props.user.user.orgId + '/' + uuidv4() + files[i].name.replace(/\s/g, "")
                });
                let uploadStatus = await axios.put(data.data.presignedURL, files[i], {
                    headers: {
                        "Content-Type": "binary/octet-stream",
                        "Access-Control-Allow-Origin": "*",
                    }
                });
                uploadedFiles.push({
                    path: cloudFrontEndpoint + data.data.contentStorageKey,
                    name: files[i].name,
                    type: files[i].type,
                    size: files[i].size,
                    status: uploadStatus.status == 200 ? true : false
                });
            } else {
                uploadedFiles.push(files[i]);
            }
        }

        return uploadedFiles;
        //  console.log(uploadedFiles, 'uploadedFiles')
    }

    saveTemplate = async () => {







        let templateData = trimObj(this.state.template);
        const formStatus = isFormValid(templateFields, this.formFields.formValidations, templateData);
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
        } else {




            if (this.state.uploadedFilesInfo && this.state.uploadedFilesInfo.length) {
                if (this.state.uploadedFilesInfo.some(a => !isValidFileSize(a.size))) {
                    this.toast.show({ severity: 'error', summary: 'Invalid Attachments', detail: 'Attachments can not be more than 20MB. Please remove', life: 3000 });
                    return;
                } else {

                }

            }


            let url = `/template/save`
            if (this.props.template) {
                url = `/template/update`
            }

            this.setState({
                isLoading: true,
            });

            let uploadedFiles = await this.uploadFilesToS3(this.state.uploadedFilesInfo);
            templateData.attachments = uploadedFiles || [];



            this.serverlessService.post(url, templateData, true).then((res) => {

                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                    }, () => {


                        this.props.onSave(this.props.template ? true : false);
                    })
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
                }

            }).catch(e => {
                console.log(e);
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            })

        }

    }

    componentDidMount() {
        if (this.props.isSessionChecked && !this.props.authenticated) {
            this.props.history.push('/login')
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSessionChecked != this.props.isSessionChecked && (this.props.isSessionChecked && !this.props.authenticated)) {
            this.props.history.push('/login')
        }

    }

    footer = () => {
        return (<div>
            <Button label={`${this.props.template ? 'Update' : 'Save'} Template`} className='sp-m-lr10'
                onClick={this.saveTemplate}
            />
        </div>)
    }



    _removefile = (fileInf) => {
        let uploadedFiles = [];
        this.state.uploadedFilesInfo.forEach((fileInfo) => {
            if (fileInfo.name !== fileInf.name) {
                uploadedFiles = [...uploadedFiles, fileInfo];
            }
        });

        this.setState({
            uploadedFilesInfo: uploadedFiles
        });
    }

    showAttachment = (attachment) => {
        if (attachment.path) {
            window.open(attachment.path);
        } else {
            let src = URL.createObjectURL(attachment);
            window.open(src);
        }
    }

    filesUploadedBindingInfo = (filesInfo = []) => {
        let temp;
        temp = filesInfo.map((fileInfo) => {
            return <div key={fileInfo.name}>
                <div className="p-grid upload" dir="ltr">

                    <div style={{ width: '100%', overflow: 'hidden' }}>
                        <div style={{ width: '30px', float: 'right' }}>
                            <span className="btn-remove-file sp-float-right"
                                onClick={() => this._removefile(fileInfo)}><i className='pi pi-times' /></span>

                        </div>
                        <div style={{ marginRight: '30px' }} >
                            <p onClick={() => { this.showAttachment(fileInfo) }} className={`filename ${isValidFileSize(fileInfo.size) ? 'valid-file' : "invalid-file"}`}>{fileInfo.name} <span className='sp-right'> {bytesToSize(fileInfo.size)}</span></p>
                        </div>
                    </div>


                </div>
            </div>
        });
        return temp;
    }

    _afterFilesUploaded = (files) => {
        for (let i = 0; i < files.length; i++) {
            this.setState((prevState, prevProps) => ({
                uploadedFilesInfo: [...prevState.uploadedFilesInfo, files[i]]
            }));
        }
    }



    render() {
        const { template, formValidations } = this.state;

        console.log(this.state, 'state-appointment')
        return (<>
            <Dialog
                header={`${this.props.template ? 'Edit' : 'Create'} Template`}
                blockScroll={true}
                draggable={false}
                closeOnEscape={false}
                dismissableMask={false}
                visible={true}
                footer={this.footer}
                closable={true}
                style={{ width: '90%', height: '90vh' }}
                onHide={this.props.onHide}>

                <div>
                    <div className="p-grid sp-mt20">

                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label'>Name<span className='sp-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                            <InputText value={template.name}
                                className='sp-w100p p-inputtext-style1'
                                onChange={(e) => { onTextChange(e.target.value, 'name', this, templateFields, template, formValidations, 'template', 'formValidations') }} />
                            {formValidations && !formValidations.fields['name'].isValid && <p className="p-error">{formValidations.fields['name'].errorMsg}</p>}
                        </div>
                    </div>
                    <div className="p-grid ">
                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label' >Description<span className='sp-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                            <InputTextarea value={template.description}
                                rows={3}
                                className='sp-w100p p-inputtext-style1'
                                onChange={(e) => { onTextChange(e.target.value, 'description', this, templateFields, template, formValidations, 'template', 'formValidations') }} />
                            {formValidations && !formValidations.fields['description'].isValid && <p className="p-error">{formValidations.fields['description'].errorMsg}</p>}
                        </div>
                    </div>
                    <div className="p-grid sp-mt20">

                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label'>Subject<span className='sp-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                            <InputText value={template.subject}

                                className='sp-w100p p-inputtext-style1'
                                onChange={(e) => { onTextChange(e.target.value, 'subject', this, templateFields, template, formValidations, 'template', 'formValidations') }} />
                            {formValidations && !formValidations.fields['subject'].isValid && <p className="p-error">{formValidations.fields['subject'].errorMsg}</p>}
                        </div>
                    </div>
                    <div className="p-grid ">
                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label' >Message<span className='sp-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                            <ClassicEditor
                                data={template.message}
                                onChange={(value) => { onTextChange(value, 'message', this, templateFields, template, formValidations, 'template', 'formValidations') }}
                            />

                            {formValidations && !formValidations.fields['message'].isValid && <p className="p-error">{formValidations.fields['message'].errorMsg}</p>}
                        </div>
                    </div>
                    {/* <div dangerouslySetInnerHTML={{ __html: template.template }}></div> */}


                    <div className="p-grid ">
                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label' >Attachments<span className='sp-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">

                            <div className="attachment-wrapper" >
                                <div className='attachments'>
                                    {this.filesUploadedBindingInfo(this.state.uploadedFilesInfo)}

                                </div>
                                <p className="sp-danger">{this.state.needHelpErr?.filesErr}</p>
                                <div className='sp-clearFix'></div>

                                <div className='file-upload-w'>
                                    <FileUpload id="returnWindow"
                                        multiple={true}
                                        afterFilesUploaded={this._afterFilesUploaded}
                                    />
                                </div>
                            </div>
                            <p className='upload-note sp-mt20'><span>Note:</span>Attachments can not be more than 20MB.</p>


                        </div>
                    </div>
                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}

            </Dialog>
        </>
        )
    }
}






const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    isSessionChecked: state?.user?.isSessionChecked,
});


export default connect(mapStateToProps, {

})(Authentication(withRouter(CreateTemplate)));
