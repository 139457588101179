import React, { Component } from 'react'

import { Dialog } from 'primereact/dialog';


export default class TemplateView extends Component {
    constructor(props) {
        super(props);

    }





    render() {
        const { template } = this.props;
        return (
            <Dialog header={'Template Details'}
                blockScroll={true} draggable={false}
                closeOnEscape={true}
                dismissableMask={true} visible={true}
                closable={true}
                style={{ width: '50%' }}
                onHide={this.props.onHide}>
                <div className='appointment-info'>
                    {/* <div>
                        <span className='label-l1'> Template Name: </span>
                        <span className='label-v1'> {template.templateName}</span>
                    </div> */}
                    <div>
                        <span className='label-l1'> Template Name: </span>
                        <p>{template.templateName}</p>
                        <span className='label-l1'> Template Id: </span>
                        <p>{template.templateId}</p>
                        <span className='label-l1'> Template Message: </span>
                        <p>{template.templateMessage}</p>
                        <span className='label-l1'>Sender IDs: </span>
                        <p>{template.senderIds.join(',')}</p>
                        <span className='label-l1'>Principal Enitity: </span>
                        <p>{template.peId}</p>

                    </div>
                </div>
            </Dialog >
        )
    }
}
