import React, { Component } from 'react'
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import Authentication from './../../../session/index'
import { Toast } from 'primereact/toast';
import LoadingComponent from 'src/LoadingComponent';
import Service from 'src/Service';
import { exportCSVFile, trimObj } from 'src/Utils';
import Header from 'src/Header';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { DataTable } from 'primereact/datatable';
import { Checkbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';
import { BasicLazyParams } from './../../../Utils/constants';
import { Toolbar } from 'primereact/toolbar';
import ServerlessService from 'src/Service/ServerlessService';

class SMSReports extends Component {
    constructor(props) {
        super(props);

        this.state = {
            globalFilter: '',
            columns: [
                //    { columnName: 'Password', isVisible: false, id: 'password' }
            ],
            lazyParams: { ...BasicLazyParams, sortField: 'createdAt', sortOrder: -1 },
            globalSearch: '',
            totalRecords: 0,
            data: [],
            smsStatus: null,
            filter: {}
        }

        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }


    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }

    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }


    componentDidMount() {
        this.getReports();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user != this.props.user) {

            this.getReports();
        }

    }

    refresh = () => {
        this.setState({
            lazyParams: { ...BasicLazyParams, sortField: 'createdAt', sortOrder: -1 },
        }, () => { this.getReports() })
    }

    setAndApplyFiler = (key) => {
        debugger;

        if (key == 'All') {
            this.setState({
                filter: {

                },
                lazyParams: { ...BasicLazyParams, sortField: 'createdAt', sortOrder: 1 },
            }, () => this.getReports())
        } else {
            this.setState({
                filter: {
                    status: key
                },
                lazyParams: { ...BasicLazyParams, sortField: 'createdAt', sortOrder: 1 },
            }, () => this.getReports())
        }


    }


    getReports = (transactionId) => {
        let { lazyParams, globalSearch } = this.state;
        this.setState({
            isLoading: true,
            data: [],
            transactionId
        });
        const url = `/sms-bulk/all?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`;
        this.serverlessService.get(url, true).then((res) => {

            debugger

            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                    data: res.res.data || [],
                    totalRecords: res.res.totalRecords,
                    smsStatus: res.res.smsStatus,

                })

                // this.toast.show({ severity: 'success', summary: 'Success', detail: 'Templates fetched successfully.', life: 3000 });
            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })
    };

    onPage = (event) => {
        this.setState({ lazyParams: event },
            () => { this.getReports() });
    }

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, () => { this.getReports() });
    }

    downloadCSV = () => {

        const { smsStatus } = this.state;

        let headers = [];

        Object.keys(smsStatus).forEach(k => {
            headers.push(k);
        })

        exportCSVFile(headers, [smsStatus], 'allreport')



    }


    render() {

        console.log(this.state.data, 'details');
        const { smsStatus } = this.state
        const header = (
            <div className="table-header sp-tbl-header">
                <div className="sp-tbl-left">
                    <h2 >All Messages</h2>
                    {this.state.showSearch && <InputText placeholder="Search"
                        className="sp-tbl-search p-inputtext-md"
                        onChange={this.onGlobalSearch}
                        value={this.state.globalSearch}
                    />}
                </div>
                <div className="p-input-icon-left sp-tbl-filter">
                    <ul className="sp-tbl-li">
                        <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search sp-tbl-icon sp-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                        <Tooltip className="table-li-tooltip" autoHide={false} target=".sp-tbl-srch" />
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter sp-tbl-icon sp-tbl-fi"></i></li>
                            <Tooltip className="table-li-tooltip" target=".sp-tbl-fi" /> */}
                        {/* <li><i onClick={(event) => this.menu.toggle(event)} data-pr-tooltip="View Columns" data-pr-position="bottom" className="pi pi-sort-alt sp-tbl-icon sp-tbl-sort"></i></li>
                            <Tooltip className="table-li-tooltip" target=".sp-tbl-sort" /> */}
                        <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download sp-tbl-icon sp-tbl-dwnld" onClick={this.exportCSV}></i></li>
                        <Tooltip className="table-li-tooltip" target=".sp-tbl-dwnld" />
                    </ul>
                </div>
                <Menu
                    className='table-filter-w'
                    model={[
                        {
                            template: (<div className='table-filter'>
                                <h4 className="filter-title">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <div onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="p-checkbox-label p-name">{column.columnName}</label>
                                        </div>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );


        const { peConfig, formValidations, isEdit, isNotAdded } = this.state;
        return (

            <Header >
                <div className='sp-main'>
                    <Toolbar className="sp-toolbar"
                        right={() => {
                            return (
                                // <Authorizer permId={PERMISSIONS.EMAIL_COMPAIGN_REFRESH} >
                                <Button label="Refresh" className='sp-mlr30'
                                    icon="pi pi-refresh"
                                    disabled={this.state.isLoading}
                                    onClick={() => {
                                        this.refresh()
                                    }} />
                                // </Authorizer>
                            )
                        }}



                    ></Toolbar>
                    <div className='sp-clearFix'></div>
                    <div className='email-comaign-insights'>
                        <h2 className=''>Insights <span onClick={() => { this.downloadCSV() }} className='sp-right'><i className='pi pi-download'></i></span></h2>

                        {
                            smsStatus && Object.keys(smsStatus).map(key => {
                                return <span onClick={() => { this.setAndApplyFiler(key) }} >{key}  : {smsStatus[key]}  </span>

                            })
                        }
                    </div>


                    <div className="card datatable-crud-demo sp-m30">
                        <DataTable ref={(el) => this.dt = el}
                            lazy
                            value={this.state.data}
                            // selection={this.state.selectedProducts}
                            // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}
                            dataKey="_id"
                            paginator
                            onPage={this.onPage}
                            first={this.state.lazyParams.first}
                            last={this.state.totalRecords}
                            rows={this.state.lazyParams.rows}
                            totalRecords={this.state.totalRecords}
                            rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 300, 500]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            globalFilter={this.state.globalFilter}
                            header={header}>

                            <Column field="phoneNumber" header="Phone Number" />
                            <Column field="senderId" header="Sender Id" />
                            <Column field="message" header="Message" />
                            <Column field="status" header="Status" />

                            <Column field="statusMessage" header="Status Message" />
                            <Column field="createdAt" header="Date" body={(rowData) => {
                                return (
                                    <div className="ma-status">
                                        {moment(rowData.createdAt).format("DD-MM-YYYY, h:mm:ss a")}
                                    </div>
                                )
                            }} />
                        </DataTable>
                    </div>

                    {
                        this.state.isLoading && <LoadingComponent />
                    }
                    <Toast ref={(el) => this.toast = el} position="bottom-right" />

                </div>
            </Header>
        )
    }


}

const mapStateToProps = (state) => ({
    // peConfig: state.orgData.peConfig,
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    isSessionChecked: state?.user?.isSessionChecked,
});



export default connect(mapStateToProps, {

})(Authentication(withRouter(SMSReports)));

