import React from 'react';
import { Toolbar } from 'primereact/toolbar';
import XLSX from 'xlsx';
import { connect } from 'react-redux';
import Header from './../../Header';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { BreadCrumb } from 'primereact/breadcrumb';
import { DataTable } from 'primereact/datatable';
import moment from "moment";
import { Toast } from 'primereact/toast';
import LoadingComponent from './../../LoadingComponent';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import './styles.scss'
import BulkUploadDetails from './bulkUploadDetails';
import Authentication from './../../session';
import { Dialog } from 'primereact/dialog';
import { BasicLazyParams } from 'src/Utils/constants';
import NewUpload from './newUpload';
import Service from 'src/Service';
import Authorizer, { PERMISSIONS } from './../../session/authorize';
import ServerlessService from 'src/Service/ServerlessService';
const home = { icon: 'pi pi-home' }
const items = [
    { label: 'Email Service' },
    { label: 'Bulk Email Users Upload', className: 'sp-active-item' }
];

/* list of supported file types */
const SheetJSFT = ['xlsx', 'xlsb', 'xlsm', 'xls', 'csv']
    .map(function (x) {
        return '.' + x;
    })
    .join(',');

/* generate an array of column objects */
const make_cols = (refstr) => {
    let o = [],
        C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
    return o;
};

const SampleBulkUplodData = [];
class BulkEmailUsersUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            openSnackBar: false,
            users: [],
            showMissedListTable: false,
            showUploadBtn: false,
            showLoader: false,
            transactionId: null,
            totalRecords: 0,
            globalSearch: '',
            lazyParams: BasicLazyParams,
            transactions: [],
            transactionDetails: []
        };

        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }


    handleClickOpen = () => {
        this.setState({ open: true });
    };
    handleClickOpenDelete = () => {
        this.setState({ openDelete: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleDeleteClose = () => {
        this.setState({ openDelete: false });
    };

    getTransactions = () => {
        let { lazyParams, globalSearch } = this.state;

        this.setState({
            isLoading: true,
            appointments: []
        });
        const url = `/emailusers-bulk-trans/all?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`;
        this.serverlessService.post(url, { status: this.state.currentFilter }, true).then((res) => {


            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                    transactions: res.res.data,
                    totalRecords: res.res.totalRecords
                })

                // this.toast.show({ severity: 'success', summary: 'Success', detail: 'Templates fetched successfully.', life: 3000 });
            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })







    };

    componentDidMount = () => {
        this.getTransactions();
    };

    componentWillUnmount() {
        //   this.props.clearBulkUserUploadData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.error !== this.props.error) {
            if (this.props.error) {
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'Failed to upload bulk users data', life: 5000 });
            }
        }

        if (prevProps.bulkUserUploadSaveResponse !== this.props.bulkUserUploadSaveResponse) {
            if (this.props.bulkUserUploadSaveResponse) {
                const transactionId = this.props.bulkUserUploadSaveResponse.transaction_id ? this.props.bulkUserUploadSaveResponse.transaction_id : '';
                this.toast.show({ severity: 'success', summary: 'Process is initiated', detail: `Bulk users upload process initiated, kindly refer the transaction ${transactionId} details in below table`, life: 10000 });
                this.getTransactions();
            }
        }
    }

    handleFile = (file) => {
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        let data = [];
        reader.onload = (e) => {
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            data = XLSX.utils.sheet_to_json(ws, { raw: false });
            this.setState({ data: data, cols: make_cols(ws['!ref']) });

            const newJsonSheet = [];
            for (let i = 0; i < data.length; i++) {
                let excelRowData = {
                    ...data[i],
                    __rowNum__: undefined
                };

                excelRowData = JSON.parse(JSON.stringify(excelRowData));

                newJsonSheet.push(excelRowData);
            }

            const users = newJsonSheet;

            // .map((record) => ({
            //     ...record,
            //     mobile: `+91${record.mobile}`
            // }));

            this.setState({ users });
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
    };

    handleChange = (e) => {
        const files = e.target.files;
        if (files && files[0]) {
            this.setState({ fileSelected: files[0]['name'] });
            this.handleFile(files[0]);
            e.target.value = null;
        }
    };
    exportCSV = () => {
        this.dt.exportCSV();
    }
    exportCSV2 = () => {
        this.dt2.exportCSV();
    }

    onPage = (event) => {
        this.setState({ lazyParams: event }, this.getTransactions);
    }

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, this.getTransactions);
    }


    uploadBulkUsers = () => {
        const payload = {
            //  user_id: this.props.userId,
            users: this.state.users
        };

        console.log(payload, 'payload')

        // this.setState({
        //     users: [],
        //     fileSelected: null
        // }, () => {
        //     this.props.saveBulkUploadUsers(payload);
        // });
    };

    getTransactionDetails = (transactionId) => {

        this.setState({
            isLoading: true,
            transactionDetails: [],
            transactionId
        });
        const url = `/emailusers-bulk-trans/${transactionId}`;
        this.serverlessService.get(url, true).then((res) => {


            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                    transactionDetails: res.res.data || [],
                    //  totalRecords: res.res.totalRecords
                })

                // this.toast.show({ severity: 'success', summary: 'Success', detail: 'Templates fetched successfully.', life: 3000 });
            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })
    };

    onUpload = (transactionId) => {

        this.toast.show({ severity: 'success', summary: 'Initiated', detail: `Bulk Upload iniaited, please check the status with reference number '${transactionId}'`, life: 12000 });
        this.setState({
            isShowNewUpload: false,
            lazyParams: BasicLazyParams,
            globalSearch: ''

        }, this.getTransactions);
    }


    render() {
        const header = (
            <div className="table-header sp-tbl-header">
                <div className="sp-tbl-left">
                    <h2 >Transactions List</h2>
                    {this.state.showSearch && <InputText placeholder="Search" className="sp-tbl-search p-inputtext-md"
                        value={this.state.globalSearch}

                        onChange={this.onGlobalSearch} />}
                </div>
                <div className="p-input-icon-left sp-tbl-filter">
                    <ul className="sp-tbl-li">
                        <Authorizer permId={PERMISSIONS.BULK_EMAIL_USERS_GENERAL_SEARCH} >
                            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search sp-tbl-icon sp-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".sp-tbl-srch" />
                        </Authorizer>
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter sp-tbl-icon sp-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".sp-tbl-fi" /> */}
                        {/* <li><i data-pr-tooltip="View Columns" data-pr-position="bottom" className="pi pi-sort-alt sp-tbl-icon sp-tbl-sort"></i></li>
                        <Tooltip className="table-li-tooltip" target=".sp-tbl-sort" /> */}
                        <Authorizer permId={PERMISSIONS.BULK_EMAIL_USERS_GENERAL_DOWNLOAD} >
                            <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download sp-tbl-icon sp-tbl-dwnld" onClick={this.exportCSV}></i></li>
                            <Tooltip className="table-li-tooltip" target=".sp-tbl-dwnld" />
                        </Authorizer>
                    </ul>
                </div>
            </div>
        );

        const header2 = (
            <div className="table-header sp-tbl-header">
                <div className="sp-tbl-left">
                    <h2 >Sample Bulk Upload Sheet</h2>
                </div>
                <div className="p-input-icon-left sp-tbl-filter">
                    <ul className="sp-tbl-li">
                        <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download sp-tbl-icon sp-tbl-dwnld" onClick={this.exportCSV2}></i></li>
                        <Tooltip className="table-li-tooltip" target=".sp-tbl-dwnld" />
                    </ul>
                </div>
            </div>
        );

        return (
            <Header>
                <div className='bulk-upload'>
                    {/* <Authorizer permId={PERMISSIONS.USER_BULK_USERS_UPLOAD} > */}
                    <div className=''>

                        <div className="p-grid sp-toolbar">
                            <div className="p-col-12 p-md-1 sp-no-p">
                            </div>
                            <div className="p-col-12 p-md-11 sp-no-pm">
                                <Toolbar className="sp-toolbard" left={() => {
                                    return (
                                        <React.Fragment>
                                            <BreadCrumb model={items} home={home} />
                                        </React.Fragment>
                                    )
                                }}
                                    right={() => {
                                        return (
                                            <React.Fragment>
                                                <h2 className='sp-tab-title'>Bulk Email Users Upload</h2>
                                            </React.Fragment>
                                        )
                                    }}></Toolbar>
                            </div>
                        </div>
                        {
                            this.state.isShowNewUpload ? <>
                                <NewUpload cancelUpload={() => {
                                    this.setState({
                                        isShowNewUpload: false
                                    })
                                }}
                                    onUpload={this.onUpload}

                                />
                            </> : <>
                                <Toolbar className="sp-toolbar"
                                    right={() => {
                                        return (
                                            <Authorizer permId={PERMISSIONS.BULK_EMAIL_USERS_NEW_UPLOAD} >
                                                <Button label="New Upload" className='sp-mlr30'
                                                    icon="pi pi-plus"
                                                    onClick={() => {
                                                        this.setState({
                                                            isShowNewUpload: true
                                                        })
                                                    }} /></Authorizer>
                                        )
                                    }}></Toolbar>
                                <div className="p-grid sp-toolbar">
                                    <Authorizer permId={PERMISSIONS.BULK_EMAIL_USERS_LIST} >
                                        <div className="card datatable-crud-demo sp-m30">
                                            <DataTable ref={(el) => this.dt = el}
                                                //  lazy
                                                value={this.state.transactions}
                                                // selection={this.state.selectedProducts}
                                                // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}
                                                dataKey="_id"
                                                paginator
                                                lazy
                                                onPage={this.onPage}
                                                first={this.state.lazyParams.first}
                                                last={this.props.totalRecords}
                                                rows={this.state.lazyParams.rows}
                                                totalRecords={this.props.totalRecords}
                                                rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 300, 500]}
                                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                                // globalFilter={this.state.globalFilter}
                                                header={header}>
                                                <Column filterField='transactionId' field="transactionId" header="Transaction Id" />
                                                <Column filterField='bulkStatus' field="bulkStatus" header="Status" />
                                                <Column field="initiatedBy" header="Initiated By" body={(rowData) => {
                                                    return (<>
                                                        {rowData.initiatedBy.name}
                                                    </>)
                                                }} />
                                                <Column field="uploadedAt" header="Date" body={(rowData) => {
                                                    return (<>
                                                        <span>{moment(rowData.uploadedAt).format('LL')}</span>
                                                    </>)
                                                }} />
                                                <Column header='Actions' body={(rowData) => {
                                                    return (<>
                                                        <Authorizer permId={PERMISSIONS.BULK_EMAIL_USERS_SHOW_DETAILS} >
                                                            <Button label="Show Details" onClick={() => this.getTransactionDetails(rowData.transactionId)} className='p-button-outlined' />
                                                        </Authorizer>
                                                    </>
                                                    );
                                                }} ></Column>
                                            </DataTable>
                                        </div>
                                    </Authorizer>
                                </div>
                                {this.state.transactionDetails && this.state.transactionDetails.length ? (
                                    <BulkUploadDetails transactionId={this.state.transactionId} data={this.state.transactionDetails} />
                                ) : null}
                            </>
                        }

                    </div>

                    {this.state.isLoading ? <LoadingComponent /> : null}
                    <Toast ref={(el) => this.toast = el} position="bottom-right" />
                    {/* </Authorizer> */}
                </div>
            </Header>
        );
    }
}


const mapStateToProps = (state) => ({
    // loading: state.createNewUserInfo && state.createNewUserInfo.loading,
    // error: state.createNewUserInfo && state.createNewUserInfo.error,
    // bulkUserUploadTransactions: state.createNewUserInfo && state.createNewUserInfo.bulkUserUploadTransactions && state.createNewUserInfo.bulkUserUploadTransactions && state.createNewUserInfo.bulkUserUploadTransactions.bulkTransaction,
    // totalRecords: state.createNewUserInfo && state.createNewUserInfo.bulkUserUploadTransactions && state.createNewUserInfo.bulkUserUploadTransactions && state.createNewUserInfo.bulkUserUploadTransactions.totalRecords,
    // bulkUserUploadTransactionDetails: state.createNewUserInfo && state.createNewUserInfo.bulkUserUploadTransactionDetails && state.createNewUserInfo.bulkUserUploadTransactionDetails.bulkTransaction && state.createNewUserInfo.bulkUserUploadTransactionDetails.bulkTransaction[0] && state.createNewUserInfo.bulkUserUploadTransactionDetails.bulkTransaction[0].output_json,
    // bulkUserUploadSaveResponse: state.createNewUserInfo && state.createNewUserInfo.bulkUserUploadSaveResponse && state.createNewUserInfo.bulkUserUploadSaveResponse,
    // bulkUserUploadRequested: state.createNewUserInfo && state.createNewUserInfo.bulkUserUploadRequested && state.createNewUserInfo.bulkUserUploadRequested,
    // userId: localStorage.getItem('username'),
});

export default connect(mapStateToProps, {
    // getBulkUploadTransactionDetails,
    // getBulkUploadTransactions,
    // saveBulkUploadUsers,
    // clearBulkUserUploadData
})(Authentication(BulkEmailUsersUpload));
